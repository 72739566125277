import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

 

export default new Vuex.Store({
    state: {
        lang: {
            Home: '<strong>�LKER B�SK�V�</strong> 2020 �zet Faaliyet Raporu'
        },
        getlang(l) {
            return this.$route.params.lang;
        }
       
    },
    mutations: {
        getLanguage(state, value) {
            state.lang = value;
        }
    },
    actions: {
        changeLanguage({ commit }, value) {
            alert(value);
            commit('getLanguage', value);
        }
    },
  modules: {}
})
