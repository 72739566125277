import Vue from 'vue'
import VueRouter from 'vue-router'
 

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import('../Layouts/mainlayout.vue'),
        children: [  
            { path: '/', name: 'start', component: () => import('../views/Home.vue') },
            { path: '/:lang', name: 'Home', component: ()=>import('../views/Home.vue') },
            { path: '/:lang/chairman', name: 'chairman', component: () => import('../views/Chairman.vue') },
            { path: '/:lang/ceo', name: 'ceo', component: () => import('../views/ceo.vue') },
            { path: '/:lang/financial', name: 'financial', component: () => import('../views/Financial.vue') }  ,
            { path: '/:lang/pladis', name: 'pladis', component: () => import('../views/Pladis.vue') },
            { path: '/:lang/facility', name: 'facility', component: () => import('../views/Facility.vue') },
            { path: '/:lang/marketing', name: 'marketing', component: () => import('../views/Marketing.vue') },
            { path: '/:lang/capacity', name: 'capacity', component: () => import('../views/Capacity.vue') },
            { path: '/:lang/operations', name: 'operations', component: () => import('../views/Operations.vue') },
            { path: '/:lang/investmens', name: 'investmens', component: () => import('../views/Investmens.vue') },
            { path: '/:lang/sustainability', name: 'sustainability', component: () => import('../views/Sustainability.vue') }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router
